import normalizeWheel from 'normalize-wheel';
import clamp from 'clamp';

import { ScrollOptions, ScrollInstance, EventName, ScrollListenerEvent, ListenerFunction } from './types';
import { lerp } from './utils';
import KEYCODES from '../../utils/keyCodes';
import { dispatcher } from '../../inits/dispatcher';

export * from './types';

const INITIALIZED_CLASS = 'scroll-initialized';

const defaultOptions: ScrollOptions = {
    ease: 0.1,
};

export const createScroll = (_options: Partial<ScrollOptions> = {}): ScrollInstance => {
    const html = document.documentElement;
    const scrollableContainer = document.body.querySelector('[data-scroll="container"]') as HTMLElement;
    // const focusableElements = getFocusableElements(scrollableContainer) as HTMLElement[];

    let rAF = 0;
    let shouldRender = true;
    const listeners = new Set<[EventName, (event: ScrollListenerEvent) => void]>();
    const options = { ...defaultOptions, ..._options } as ScrollOptions;

    let docScroll = 0;
    const minScroll = 0;
    let maxScroll = 0;
    let previous = 0;
    let prevTouchX = 0;
    let ro: ResizeObserver | null;

    function setDocScrollValue(value: number) {
        docScroll = clamp(value, minScroll, maxScroll);
    }

    function setDocScroll(event?: WheelEvent) {
        if (shouldRender) {
            if (event instanceof Event) {
                const normalized = normalizeWheel(event);
                setDocScrollValue(docScroll + normalized.pixelY + normalized.pixelX);
            } else {
                setDocScrollValue(docScroll);
            }
        }
    }

    function onTouchstart(event: TouchEvent) {
        if (shouldRender) {
            prevTouchX = event.touches[0].clientX;
        }
    }

    function onTouchmove(event: TouchEvent) {
        // event.preventDefault();

        if (shouldRender) {
            const x = event.touches[0].clientX;
            const delta = x - prevTouchX;
            setDocScrollValue(docScroll - delta * 2.5);
            prevTouchX = x;
        }
    }

    function translateScrollableElement() {
        scrollableContainer.style.transform = `translate3d(${-previous}px,0,0)`;
    }

    function update() {
        previous = docScroll;
    }

    function setMaxScroll() {
        const scrollWidth = (scrollableContainer.lastElementChild as HTMLElement | null)?.offsetLeft || 0;
        maxScroll = scrollWidth;
    }

    function styleHtmlElement() {
        html.classList.add(INITIALIZED_CLASS);
    }

    function removeHtmlElementStyles() {
        html.classList.remove(INITIALIZED_CLASS);
    }

    function onKeydown(event: KeyboardEvent) {
        const step = 700;

        switch (event.keyCode) {
            case KEYCODES.ARROW_DOWN:
            case KEYCODES.ARROW_RIGHT:
            case KEYCODES.SPACE:
                setDocScrollValue(docScroll + step);
                break;
            case KEYCODES.ARROW_UP:
            case KEYCODES.ARROW_LEFT:
                setDocScrollValue(docScroll - step);
                break;
            default:
                break;
        }
    }

    // function onFocus(this: HTMLElement, event: any) {
    //     // event.preventDefault();
    //     // event.target.focus({ preventScroll: true });
    //     // window.scrollTo(0, 0);
    //     // // Если фокус сработал не через таб - ничего не делаем
    //     // if (!this.classList.contains('focus-visible')) return;
    //     // const rect = this.getBoundingClientRect();
    //     // setDocScrollValue(rect.left + rect.width / 2 - viewport.width / 2);
    //     // let current = 0;
    //     // const max = 2000;

    //     // function scrolling() {
    //     //     current += 300;
    //     //     setDocScrollValue(current);
    //     //     console.log(current);

    //     //     if (current < max) {
    //     //         requestAnimationFrame(scrolling);
    //     //     }
    //     // }

    //     // requestAnimationFrame(scrolling);
    //     // setDocScrollValue(3000);
    //     // console.log(rect.left + rect.width / 2 - viewport.width / 2);
    //     // console.log(docScroll);
    // }

    function onResize() {
        setMaxScroll();
        setDocScroll();
        update();
        listeners.forEach(([eventName, fn]) => {
            if (eventName === 'scroll') {
                fn({
                    docScrollValue: docScroll,
                    scrollValue: previous,
                });
            }
        });
        requestAnimationFrame(() => {
            window.scrollTo(0, 0);
        });
    }

    function initEvents() {
        window.addEventListener('resize', onResize);
        window.addEventListener('wheel', setDocScroll, { passive: false });
        window.addEventListener('touchstart', onTouchstart, { passive: false });
        window.addEventListener('touchmove', onTouchmove, { passive: false });
        document.addEventListener('keydown', onKeydown);
        // focusableElements.forEach((el) => {
        //     el.setAttribute('tabindex', '-1');
        //     // el.addEventListener('focus', onFocus, { capture: true });
        // });
    }

    function destroyEvents() {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('wheel', setDocScroll);
        document.removeEventListener('keydown', onKeydown);
        window.removeEventListener('touchstart', onTouchstart);
        window.removeEventListener('touchmove', onTouchmove);
        // focusableElements.forEach((el) => {
        //     el.removeEventListener('focus', onFocus, { capture: true });
        // });
    }

    function initResizeObserver() {
        if (window.ResizeObserver) {
            ro = new ResizeObserver((entries) => {
                requestAnimationFrame(() => {
                    entries.forEach(setMaxScroll);
                });
            });

            ro.observe(scrollableContainer);
        }
    }

    function destroyResizeObserver() {
        if (ro) {
            ro.disconnect();
        }
    }

    const on: ListenerFunction = (eventName, fn) => {
        listeners.add([eventName, fn]);
    };

    const off: ListenerFunction = (eventName, fn) => {
        listeners.delete([eventName, fn]);
    };

    function render() {
        previous = lerp(previous, docScroll, options.ease);
        if (Math.abs(previous - docScroll) > 0.5) {
            listeners.forEach(([eventName, fn]) => {
                if (eventName === 'scroll') {
                    fn({
                        docScrollValue: docScroll,
                        scrollValue: previous,
                    });
                }
            });
            translateScrollableElement();
        }
    }

    function animate() {
        if (shouldRender) {
            render();
        }

        rAF = requestAnimationFrame(animate);
    }

    function getCurrentValue() {
        return docScroll;
    }

    function getCurrentLerpValue() {
        return previous;
    }

    function pauseRendering() {
        shouldRender = false;
    }

    function resumeRendering() {
        shouldRender = true;
    }

    function init() {
        setMaxScroll();
        styleHtmlElement();
        initEvents();
        initResizeObserver();
        update();
        dispatcher.on('popup-opened', pauseRendering);
        dispatcher.on('popup-closed', resumeRendering);
        rAF = requestAnimationFrame(animate);
    }

    function destroy() {
        cancelAnimationFrame(rAF);
        dispatcher.off('popup-opened', pauseRendering);
        dispatcher.off('popup-closed', resumeRendering);
        destroyEvents();
        destroyResizeObserver();
        listeners.clear();
        removeHtmlElementStyles();
    }

    init();

    return {
        destroy,
        getCurrentValue,
        getCurrentLerpValue,
        on,
        off,
    } as const;
};
