import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';
import type { AjaxFormSender } from '../../modules/ajax-form-sender';

const SELECTOR = '.js-ajax-form';
const sendersMap = new WeakMap<HTMLFormElement, AjaxFormSender>();
// const validatorsMap = new WeakMap<HTMLFormElement, Record<string, any>>();

let validator: any;

const showSuccessMessage = (form: HTMLFormElement, message = '') => {
    const successMessage = form.querySelector('.js-form-message__success');

    if (successMessage) {
        successMessage.textContent = message;
    }
};

const showFailureMessage = (form: HTMLFormElement, message = '') => {
    const failureMessage = form.querySelector('.js-form-message__failure');

    if (failureMessage) {
        failureMessage.textContent = message;
    }
};

const showFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.remove('form-messages--hidden');
    }
};

export const hideFormMessages = (form: HTMLFormElement) => {
    const messagesContainer = form.querySelector('.js-form-messages');
    if (messagesContainer) {
        messagesContainer.classList.add('form-messages--hidden');
    }
};

export const clearResponseMessages = (form: HTMLFormElement) => {
    const messageElements = Array.from(form.querySelectorAll('.js-form-message__success, .js-form-message__failure'));
    messageElements.forEach((el) => {
        el.textContent = '';
    });
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector('input.js-form__check-val') as HTMLInputElement | null;
    if (checkInput) {
        checkInput.value = '';
    }
};

function onSubmit(this: HTMLFormElement, event: any) {
    event.preventDefault();
    const sender = sendersMap.get(this);
    if (sender && validator.validateForm) {
        const isFormValid = validator.validateForm(this);
        if (isFormValid) {
            sender.send();
        }
    }
}

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];

    if (forms.length > 0) {
        const [{ default: createFormSender }, validatorModule] = await Promise.all([
            fetchAjaxFormSender(),
            fetchValidator(),
        ]);

        validator = validatorModule;

        forms.forEach((form) => {
            form.setAttribute('novalidate', 'novalidate');
            // const validator = createValidator(form);
            const sender = createFormSender(form, {
                shouldClearInputs: true,
                onBeforeSend: () => {
                    clearAntispamInput(form);
                },
                onSuccess: ({ success, message }: any) => {
                    if (success) {
                        showSuccessMessage(form, message);
                    } else {
                        showFailureMessage(form, message);
                    }
                },
                onError: () => {
                    showFailureMessage(form, 'Произошла ошибка при отправке формы');
                },
                onComplete: () => {
                    if (validator.clearMessages) {
                        validator.clearMessages(form);
                    }
                    showFormMessages(form);
                },
            });

            sendersMap.set(form, sender);
            form.addEventListener('submit', onSubmit);
        });
    }
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];
    forms.forEach((form) => {
        form.removeEventListener('submit', onSubmit);
    });
}

function getInstanceByElement(element: HTMLFormElement) {
    return sendersMap.get(element);
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
