import barba, { IViewData } from '@barba/core';
import youtubePopup from '../inits/youtube-popup';
import loadScript from '../../modules/load-script';

let youtubeLoaded = false;

export default {
    namespace: 'news-page',

    beforeEnter({ next }: IViewData) {
        const onYoutubeReady = () => {
            youtubePopup.init(next.container);
        };

        if (!youtubeLoaded) {
            loadScript('https://www.youtube.com/iframe_api').then(() => {
                youtubeLoaded = true;
            });
        }

        if (youtubeLoaded) {
            onYoutubeReady();
        } else {
            (window as any).onYouTubeIframeAPIReady = onYoutubeReady;
        }
    },

    beforeLeave({ current }: IViewData) {
        youtubePopup.destroy(current.container);
    },
};
