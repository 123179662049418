import { createMorphingAnimation } from './base-morphing';

export const createTreesGreenFourAnimation = (container: Element | Document = document) => {
    const svg = container.querySelector('.trees-green-4');
    return createMorphingAnimation(svg, {
        d: [
            {
                value: [
                    'M584.48,173.51c22,-32,59,-164,-48,-173c-77.83,-6.55,-66,52,-120,64c-12.08,2.68,-33,-8,-53,-4c-12.79,2.56,-13.3,15.31,-24.3,19.31s-28.7,-10.31,-40.7,-10.31s-16,8,-32,11c-11.21,2.1,-25.87,-2,-37.3,0.31c-13,2.6,-28.33,40,-42.33,46c-12.26,5.25,-36.45,-9.88,-53,-7c-23,4,-27.36,49.9,-54,79c-30.25,33,-78.41,83.86,-79.54,94c-1,9,0,21,9,27c48.95,32.62,222,-44,343,-72c55.78,-12.9,112,-13,124,-13c48.27,0,91.39,-36.91,108.17,-61.31z',
                    'M574.48,187.46c22,-32,90,-178,-17,-187c-77.83,-6.55,-66,59,-120,71c-12.08,2.68,-33,-15,-53,-11c-12.79,2.56,-13.3,22.3,-24.3,26.3s-28.7,-10.3,-40.7,-10.3s-16,8,-32,11c-11.21,2.1,-25.87,-2,-37.3,0.3c-13,2.61,-28.33,40,-42.33,46c-12.26,5.25,-36.45,-16.88,-53,-14c-23,4,-27.36,56.9,-54,86c-30.25,33.04,-99.41,90.87,-100.54,101c-1,9,0,21,9,27c48.95,32.63,222,-44,343,-72c55.78,-12.89,112,-13,124,-13c48.27,0,81.39,-36.9,98.17,-61.3z',
                ],
            },
            {
                value:
                    'M584.48,173.51c22,-32,59,-164,-48,-173c-77.83,-6.55,-66,52,-120,64c-12.08,2.68,-33,-8,-53,-4c-12.79,2.56,-13.3,15.31,-24.3,19.31s-28.7,-10.31,-40.7,-10.31s-16,8,-32,11c-11.21,2.1,-25.87,-2,-37.3,0.31c-13,2.6,-28.33,40,-42.33,46c-12.26,5.25,-36.45,-9.88,-53,-7c-23,4,-27.36,49.9,-54,79c-30.25,33,-78.41,83.86,-79.54,94c-1,9,0,21,9,27c48.95,32.62,222,-44,343,-72c55.78,-12.9,112,-13,124,-13c48.27,0,91.39,-36.91,108.17,-61.31z',
            },
        ],
        easing: 'easeInOutCubic',
        duration: 3500 * 1.5,
        loop: true,
    });
};
