import { ITransitionData } from '@barba/core';

let contentElement: Element | null;

export default {
    name: 'news-to-detail',
    from: { namespace: ['news-page', 'partners-page'] },
    to: { namespace: 'news-detail-page' },

    beforeEnter({ next }: ITransitionData) {
        contentElement = next.container.querySelector('.js-news-detail');
        if (contentElement) {
            contentElement.classList.remove('lit-popup--opened');
        }
    },

    enter() {
        if (contentElement) {
            requestAnimationFrame(() => {
                contentElement!.classList.add('lit-popup--opened');
            });
        }
    },

    beforeLeave() {
        contentElement = null;
    },
};
