import anime from 'animejs';
import type { AnimeInstance, AnimeTimelineInstance } from 'animejs';

import { supportsCssPathMorphing } from './base-morphing';
import { destroyAnimeInstance } from '../utils/destroy-anime-instance';

export const createCowTailContainerAnimation = (container: Element | Document = document) => {
    const targetElement = container.querySelector('.js-cow-tail');
    const tailBrushGroup = container.querySelector('.js-cow-tail-brush-path-group');
    const tailGroup = container.querySelector('.js-cow-tail-path-group');

    if (!targetElement) {
        throw new Error('Element not found.');
    }

    let animation: AnimeTimelineInstance | null;
    let tailAnimation: AnimeInstance | null;
    let tailBrushAnimation: AnimeInstance | null;

    function checkEntry(entry: IntersectionObserverEntry) {
        if (entry.isIntersecting) {
            if (animation) {
                animation.play();
            }

            if (tailAnimation) {
                tailAnimation.play();
            }

            if (tailBrushAnimation) {
                tailBrushAnimation.play();
            }
        } else {
            if (animation) {
                animation.pause();
            }

            if (tailAnimation) {
                tailAnimation.pause();
            }

            if (tailBrushAnimation) {
                tailBrushAnimation.pause();
            }
        }
    }

    function callback(entries: IntersectionObserverEntry[]) {
        entries.forEach(checkEntry);
    }

    const observer = new IntersectionObserver(callback);

    if (!supportsCssPathMorphing) {
        animation = anime.timeline({ targets: targetElement, loop: true, easing: 'easeInOutCubic' });
        animation.add({ translateX: ['0%', '62%'], duration: 3550 * 0.49 }).add({
            translateX: ['62%', '0%'],
            duration: 3550 * 0.51,
        });
        animation.pause();

        tailAnimation = anime({
            targets: tailGroup?.querySelector('.morphing'),
            d: [
                {
                    value: [
                        'M177.31,20.07c-15.31,10.64,-49.31,38.16,-63.31,107.4c-14.71,73.05,-43.5,180.69,-51,192.3c-0.89,1.38,-4,2.73,-5.41,2c-3.91,-2.16,-2,-5.68,-1.32,-6.64c9.45,-12.63,32.49,-117.39,45.34,-195.31c11.37,-69,41.27,-106.73,55,-118.76z',
                        'M66.11,18.2c-12.51,6.42,-43.66,37.18,-49.66,107.47c-9.05,79.84,12.55,194.65,6.24,206.95c-0.75,1.46,-3.73,3.12,-5.19,2.49c-4.11,-1.76,-2.6,-5.45,-2,-6.47c8.18,-13.52,-18.91,-124.89,-13.87,-203.71c4.47,-69.75,30.47,-110.3,42.94,-123.63z',
                    ],
                },
                {
                    value:
                        'M177.31,20.07c-15.31,10.64,-49.31,38.16,-63.31,107.4c-14.71,73.05,-43.5,180.69,-51,192.3c-0.89,1.38,-4,2.73,-5.41,2c-3.91,-2.16,-2,-5.68,-1.32,-6.64c9.45,-12.63,32.49,-117.39,45.34,-195.31c11.37,-69,41.27,-106.73,55,-118.76z',
                },
            ],
            easing: 'easeInOutCubic',
            duration: 3550,
            loop: true,
        });
        tailAnimation.pause();

        tailBrushAnimation = anime({
            targets: tailBrushGroup?.querySelector('.morphing'),
            d: [
                {
                    value: [
                        'M65.29,336.48c2.29,-4.72,5.37,-13.67,-2.74,-21.28c-12.09,-11.34,-29.17,3.86,-35.49,15.44c-17,31.2,-26.38,29.79,-27,31c-1,2,26.16,10.53,46.8,-3.5c9.94,-6.75,16.43,-17.53,18.43,-21.66z',
                        'M35.07,340.1c-0.85,-5.19,-3.49,-14.27,-14.5,-15.82c-16.42,-2.28,-21.63,19.95,-20.13,33.05c4,35.32,5.59,39.55,5.77,40.95c0.3,2.22,17.46,-6.46,26.25,-29.81c4.25,-11.29,3.35,-23.84,2.61,-28.37z',
                    ],
                },
                {
                    value:
                        'M65.29,336.48c2.29,-4.72,5.37,-13.67,-2.74,-21.28c-12.09,-11.34,-29.17,3.86,-35.49,15.44c-17,31.2,-26.38,29.79,-27,31c-1,2,26.16,10.53,46.8,-3.5c9.94,-6.75,16.43,-17.53,18.43,-21.66z',
                },
            ],
            easing: 'easeInOutCubic',
            duration: 3550,
            loop: true,
        });
        tailBrushAnimation.pause();
    }

    observer.observe(targetElement);

    const destroy = () => {
        observer.unobserve(targetElement);

        if (animation) {
            destroyAnimeInstance(animation);
            animation = null;
        }
    };

    return { destroy } as const;
};
