export const fetchGlide = () =>
    Promise.all([
        import(/* webpackChunkName: "glide" */ '@glidejs/glide'),
        import(/* webpackChunkName: "glide-css" */ '../css/components/_base-slider.scss'),
    ]).then(([slider]) => slider);

export const fetchLightGallery = () =>
    Promise.all([
        import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js'),
        import(/* webpackChunkName: "lightgallery-css" */ '../css/components/_lightgallery.scss'),
    ]);

export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    );

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchImask = () =>
    import(
        'imask'
        /* webpackChunkName: "imask" */
    );
