import type { IViewData } from '@barba/core';

import { createScroll } from '../components/scroll';
import { createTreesGreenOneAnimation } from '../animations/trees-green-1';
import { createTreesGreenTwoAnimation } from '../animations/trees-green-2';
import { createTreesGreenThreeAnimation } from '../animations/trees-green-3';
import { createTreesGreenFourAnimation } from '../animations/trees-green-4';
import { createTreesOrangeOneAnimation } from '../animations/trees-orange-1';
import { createCowTailContainerAnimation } from '../animations/cow-tail';
import { createMainPageRevealAnimation } from '../animations/main-page-reveal';
import type { ScrollInstance, ScrollListenerEvent } from '../components/scroll';
import type { MorphingAnimation } from '../animations/base-morphing';
import { dispatcher } from '../inits/dispatcher';
import { createParallax } from '../components/parallax';
import type { Parallax } from '../components/parallax';

let scroll: ScrollInstance | null;
let indexCollapseBtn: Element | null;
let indexCollapseCloseBtn: Element | null;
let treesGreenOneAnimation: MorphingAnimation | null;
let treesGreenTwoAnimation: MorphingAnimation | null;
let treesGreenThreeAnimation: MorphingAnimation | null;
let treesGreenFourAnimation: MorphingAnimation | null;
let treesOrangeOneAnimation: MorphingAnimation | null;
let cowTailContainerAnimation: MorphingAnimation | null;
let parallaxes: Parallax[] = [];

function onScroll({ scrollValue, docScrollValue }: ScrollListenerEvent) {
    dispatcher.dispatch('scroll', { scrollValue, docScrollValue });
}

function onIndexCollapseBtnClick(this: Element, event: any) {
    event.preventDefault();
    const expanded = this.getAttribute('aria-expanded') as string;
    this.setAttribute('aria-expanded', expanded === 'false' ? 'true' : 'false');
}

function closeIndexCollapse() {
    const collapseBtn = document.querySelector('.js-index-collapse__btn');
    if (collapseBtn) {
        const expanded = collapseBtn.getAttribute('aria-expanded') as string;
        collapseBtn.setAttribute('aria-expanded', expanded === 'false' ? 'true' : 'false');
    }
}

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('rotated');
            } else {
                entry.target.classList.remove('rotated');
            }
        });
    },
    { rootMargin: '0% -40% 0% 40%', threshold: [0, 1] },
);

export default {
    namespace: 'index-page',

    beforeEnter({ next }: IViewData) {
        const cowHead = next.container.querySelector('.js-cow-head');

        dispatcher.once('main-page-reveal-complete', () => {
            scroll = createScroll({ ease: 0.07 });
            scroll.on('scroll', onScroll);
        });

        parallaxes = (Array.from(document.querySelectorAll('[data-parallax]')) as HTMLElement[]).map((el) =>
            createParallax(el),
        );

        if (cowHead) {
            observer.observe(cowHead);
        }

        const mainPageAnimation = createMainPageRevealAnimation();
        dispatcher.once('preloader-complete', () => {
            mainPageAnimation.play();
        });
    },

    afterEnter({ next }: IViewData) {
        indexCollapseBtn = next.container.querySelector('.js-index-collapse__btn');
        indexCollapseCloseBtn = next.container.querySelector('.js-index-collapse__close-btn');

        if (indexCollapseBtn) {
            indexCollapseBtn.addEventListener('click', onIndexCollapseBtnClick);
        }

        if (indexCollapseCloseBtn) {
            indexCollapseCloseBtn.addEventListener('click', closeIndexCollapse);
        }

        treesGreenOneAnimation = createTreesGreenOneAnimation();
        treesGreenTwoAnimation = createTreesGreenTwoAnimation(next.container);
        treesGreenThreeAnimation = createTreesGreenThreeAnimation(next.container);
        treesGreenFourAnimation = createTreesGreenFourAnimation(next.container);
        treesOrangeOneAnimation = createTreesOrangeOneAnimation(next.container);
        cowTailContainerAnimation = createCowTailContainerAnimation(next.container);
    },

    beforeLeave() {
        observer.disconnect();

        if (parallaxes) {
            parallaxes.forEach((parallax) => parallax.destroy());
            parallaxes = [];
        }

        if (indexCollapseBtn) {
            indexCollapseBtn.removeEventListener('click', onIndexCollapseBtnClick);
            indexCollapseBtn = null;
        }

        if (indexCollapseCloseBtn) {
            indexCollapseCloseBtn.removeEventListener('click', closeIndexCollapse);
        }
    },

    afterLeave() {
        if (treesGreenOneAnimation) {
            treesGreenOneAnimation.destroy();
        }

        if (treesGreenTwoAnimation) {
            treesGreenTwoAnimation.destroy();
        }

        if (treesGreenThreeAnimation) {
            treesGreenThreeAnimation.destroy();
        }

        if (treesGreenFourAnimation) {
            treesGreenFourAnimation.destroy();
        }

        if (treesOrangeOneAnimation) {
            treesOrangeOneAnimation.destroy();
        }

        if (cowTailContainerAnimation) {
            cowTailContainerAnimation.destroy();
        }

        if (cowTailContainerAnimation) {
            cowTailContainerAnimation.destroy();
        }

        if (scroll) {
            scroll.off('scroll', onScroll);
            scroll.destroy();
            scroll = null;
        }
    },
};
