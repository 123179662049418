import { dispatcher } from '../../inits/dispatcher';
import type { ScrollListenerEvent } from '../scroll';

export type Parallax = {
    readonly destroy: () => void;
};

function setEntryTargetVisibility(entry: IntersectionObserverEntry) {
    (entry.target as any)._visible = entry.isIntersecting;
}

const observer = new IntersectionObserver((entries) => {
    entries.forEach(setEntryTargetVisibility);
});

export const createParallax = (element: HTMLElement): Parallax => {
    (element as any)._visible = true;
    const speed = element.dataset.parallaxSpeed ? parseFloat(element.dataset.parallaxSpeed) : 0.2;

    function onScroll({ scrollValue }: ScrollListenerEvent) {
        if ((element as any)._visible || speed < 0) {
            // element.style.transform = `translate3d(${Math.round(scrollValue * speed)}px, 0, 0)`;
            element.style.transform = `translate3d(${scrollValue * speed}px, 0, 0)`;
        }
    }

    function init() {
        dispatcher.on('scroll', onScroll);
        observer.observe(element);
    }

    function destroy() {
        dispatcher.off('scroll', onScroll);
        observer.unobserve(element);
    }

    init();

    return { destroy } as const;
};
