import barba from '@barba/core';

import { lazyload } from '../inits/lazy-load';
import popups from '../inits/popups';
import initialScreenVideo from '../inits/initial-screen-video';
import lightgallery from '../inits/lightgallery';
import reveal from '../inits/reveal';
import floatedLabels from '../inits/floated-labels';
import ajaxForms from '../inits/ajax-forms';
import inputMasks from '../inits/masks';
import proposal from '../inits/proposal';

barba.hooks.beforeEnter((data) => {
    reveal.init(data?.next.container);
    popups.init(data?.next.container);
    initialScreenVideo.init(data?.next.container);
    lightgallery.init(data?.next.container);
    floatedLabels.init(data?.next.container);
    ajaxForms.init(data?.next.container).then(() => {
        proposal.init(data?.next.container);
    });
    inputMasks.init(data?.next.container);
});

barba.hooks.enter((data) => {
    if (data && data.current && data.next) {
        document.body.classList.replace(data.current.namespace, data.next.namespace);
    }

    lazyload.update();
});

barba.hooks.beforeLeave((data) => {
    reveal.destroy(data?.next.container);
    popups.destroy(data?.current.container);
    initialScreenVideo.destroy(data?.current.container);
    floatedLabels.destroy(data?.current.container);
    ajaxForms.destroy(data?.next.container);
    inputMasks.destroy(data?.next.container);
    proposal.destroy(data?.next.container);
});
