// /* global ROOT_PATH */
import './webpack-imports';
import './sentry';
import './polyfills';
import barba from '@barba/core';

import { registerCustomElements } from './custom-elements';
// import FadeTransition from './transitions/fade';
import RevealTransition from './transitions/reveal';
import ToMainTransition from './transitions/to-main';
import NewsToDetailTransition from './transitions/news-to-detail';
import DetailToNewsTransition from './transitions/detail-to-news';
import DetailToDetailTransition from './transitions/detail-to-detail';
import './views/_base';
import IndexPageView from './views/index';
import NewsDetailPageView from './views/news-detail';
import NewsPageView from './views/news-page';
import menu from './inits/menu';
import sound from './inits/sound';
import vacancies from './inits/vacancies';
import { initBasePageReveal } from './inits/base-page-reveal';
import { createPreloader } from './components/preloader';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';

document.addEventListener('DOMContentLoaded', () => {
    // Forces repaint, use when really needed.
    // document.documentElement.classList.add('js-ready');

    createPreloader().init();
    registerCustomElements();
    initBasePageReveal();

    if ((window as any).requestIdleCallback) {
        (window as any).requestIdleCallback(calculateScrollbarWidth);
    } else {
        calculateScrollbarWidth();
    }

    sound.init();
    menu.init();
    vacancies.init();

    barba.init({
        // transitions: [FadeTransition, NewsToDetailTransition, DetailToNewsTransition, DetailToDetailTransition],
        transitions: [
            RevealTransition,
            ToMainTransition,
            NewsToDetailTransition,
            DetailToNewsTransition,
            DetailToDetailTransition,
        ],
        views: [IndexPageView, NewsDetailPageView, NewsPageView],
        prefetchIgnore: true,
        preventRunning: true,
        timeout: 5000,
        // requestError: (trigger, action, url, response) => {
        //     // go to a custom 404 page if the user click on a link that return a 404 response status
        //     // if (action === 'click' && response.status === 404) {
        //     //     barba.go(`${ROOT_PATH}404/`);
        //     // }

        //     // prevent Barba from redirecting the user to the requested URL
        //     // this is equivalent to e.preventDefault()
        //     return false;
        // },
    });
});
