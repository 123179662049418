import anime from 'animejs';

import { dispatcher } from './dispatcher';

export function initBasePageReveal() {
    const headerReveal = anime({
        targets: '.js-header',
        duration: 1500,
        opacity: [0, 1],
    });

    headerReveal.pause();
    dispatcher.once('preloader-complete', () => headerReveal.play());
}
