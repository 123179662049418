// import { listenOnce } from '@chipsadesign/frontend-utils';

import type { Asset } from './types';
// import { isAssetReady } from './is-asset-ready';

export function loadAssets(assets: Asset[]) {
    // const timeoutMs = 10000;
    // let timeout: NodeJS.Timeout;
    // const total = assets.length;
    // let loadedCount = 0;
    const preloaderLeaves = Array.from(document.querySelectorAll('.js-preloader-leaf'));

    // if (total === 0) {
    //     return Promise.resolve();
    // }

    // function revealLeaf() {
    //     const firstNonActiveLeaf = preloaderLeaves.find((el) => !el.classList.contains('visible'));
    //     if (firstNonActiveLeaf) {
    //         firstNonActiveLeaf.classList.add('visible');
    //     }
    // }

    preloaderLeaves.forEach((el, i) => {
        setTimeout(() => {
            if (el.classList) {
                el.classList.add('visible');
            }
        }, i * 70);
    });

    // let prevTime = 0;
    // const queue = [];

    // return new Promise((resolve, reject) => {
    //     function incrementLoaded() {
    //         loadedCount++;

    //         // if (loadedCount % Math.floor(assets.length / preloaderLeaves.length) === 0) {
    //         //     if (performance.now() - prevTime < 100) {
    //         //         console.log(performance.now() - prevTime);
    //         //         prevTime = performance.now();
    //         //     }
    //         // }

    //         if (loadedCount >= total) {
    //             clearTimeout(timeout);
    //             resolve();
    //         }
    //     }

    //     assets.forEach((asset) => {
    //         if (isAssetReady(asset)) {
    //             incrementLoaded();
    //         } else {
    //             if (asset instanceof HTMLImageElement) {
    //                 listenOnce(asset, 'load', incrementLoaded);
    //             }

    //             if (asset instanceof HTMLVideoElement) {
    //                 listenOnce(asset, 'canplaythrough', incrementLoaded);
    //             }

    //             listenOnce(asset, 'error', incrementLoaded);
    //         }
    //     });

    //     timeout = setTimeout(reject, timeoutMs);
    // });
}
