import popups from './popups';
import ajaxForms, { hideFormMessages, clearResponseMessages } from './ajax-forms';
import { dispatcher } from './dispatcher';
import LitPopup from 'lit-popup';
import type { AjaxFormSender } from '../../modules/ajax-form-sender';

let proposalPopupElement: Element | null;
let proposalForm: HTMLFormElement | null;
let proposalPopup: LitPopup | undefined;
let proposalFormSender: AjaxFormSender | undefined;
let formUploadElement: Element | null | undefined;
let formUploadOpenBtn: Element | null | undefined;
let formUploadCloseBtn: Element | null | undefined;
let formUploadLoadedFileContainer: HTMLElement | null | undefined;
let formUploadLoadedFileName: HTMLElement | null | undefined;
let formUploadLoadedFileRemoveBtn: HTMLElement | null | undefined;
let formUploadDropzone: HTMLElement | null | undefined;
let timer: NodeJS.Timeout;
let formUploadClosingTimer: NodeJS.Timeout;

function onFormUploadOpenBtnClick() {
    clearTimeout(formUploadClosingTimer);

    if (formUploadElement) {
        formUploadElement.classList.add('form-upload--visible');
    }
}

function onFormUploadCloseBtnClick() {
    clearTimeout(formUploadClosingTimer);

    if (formUploadElement) {
        formUploadElement.classList.remove('form-upload--visible');
    }
}

function onProposalDropzoneSuccess({ fileName, userFileName }: { fileName: string; userFileName: string }) {
    if (proposalFormSender) {
        proposalFormSender.appendData('file', fileName);
    }

    if (formUploadLoadedFileContainer) {
        formUploadLoadedFileContainer.classList.add('is-visible');

        if (formUploadLoadedFileName) {
            formUploadLoadedFileName.textContent = userFileName;
            formUploadLoadedFileName.title = userFileName;
        }

        if (formUploadLoadedFileRemoveBtn) {
            formUploadLoadedFileRemoveBtn.dataset.filename = fileName;
        }
    }

    formUploadClosingTimer = setTimeout(() => {
        if (formUploadElement) {
            formUploadElement.classList.remove('form-upload--visible');
        }
    }, 2000);
}

function onProposalDropzoneRemove() {
    if (proposalFormSender) {
        proposalFormSender.removeData('file');
    }

    if (formUploadLoadedFileContainer) {
        formUploadLoadedFileContainer.classList.remove('is-visible');

        if (formUploadLoadedFileName) {
            formUploadLoadedFileName.textContent = '';
            formUploadLoadedFileName.title = '';
        }

        if (formUploadLoadedFileRemoveBtn) {
            formUploadLoadedFileRemoveBtn.dataset.filename = '';
        }
    }
}

function removeLoadedFile(this: HTMLElement) {
    if (formUploadDropzone) {
        const vueInstance = (formUploadDropzone as any).getVueInstance();
        if (vueInstance) {
            const { filename } = this.dataset;
            if (filename) {
                vueInstance.removeFile(filename);
            }
        }
        console.log(vueInstance);
    }
}

function resetProposalForm() {
    if (formUploadElement) {
        formUploadElement.classList.remove('form-upload--visible');
    }

    if (proposalFormSender) {
        proposalFormSender.clearInputs();
    }

    if (proposalForm) {
        hideFormMessages(proposalForm);
        clearResponseMessages(proposalForm);
    }
}

function init(container: Element | Document = document) {
    proposalPopupElement = container.querySelector('[data-lit-popup="proposal"]');
    proposalForm = container.querySelector('form.js-proposal-form') as HTMLFormElement | null;
    proposalPopup = popups.getInstanceByElement(proposalPopupElement);
    proposalFormSender = proposalForm ? ajaxForms.getInstanceByElement(proposalForm) : undefined;
    formUploadElement = proposalForm?.querySelector('.js-form-upload');
    formUploadOpenBtn = proposalForm?.querySelector('.js-proposal-upload-open');
    formUploadCloseBtn = proposalForm?.querySelector('.js-proposal-upload-close');
    formUploadLoadedFileContainer = proposalForm?.querySelector('.js-form-upload-loaded-file');
    formUploadLoadedFileName = proposalForm?.querySelector('.js-form-upload-loaded-file-name');
    formUploadLoadedFileRemoveBtn = proposalForm?.querySelector('.js-form-upload-loaded-file-remove-btn');
    formUploadDropzone = proposalForm?.querySelector('.js-proposal-dropzone');

    if (formUploadOpenBtn) {
        formUploadOpenBtn.addEventListener('click', onFormUploadOpenBtnClick);
    }

    if (formUploadCloseBtn) {
        formUploadCloseBtn.addEventListener('click', onFormUploadCloseBtnClick);
    }

    if (formUploadLoadedFileRemoveBtn) {
        formUploadLoadedFileRemoveBtn.addEventListener('click', removeLoadedFile);
    }

    if (proposalFormSender) {
        dispatcher.on('dropzone-success.proposal', onProposalDropzoneSuccess);
        dispatcher.on('dropzone-remove.proposal', onProposalDropzoneRemove);
    }

    if (proposalForm) {
        proposalForm.addEventListener('success', () => {
            timer = setTimeout(() => {
                if (proposalPopup) {
                    proposalPopup.close();
                }
            }, 4000);
        });
    }

    if (proposalPopup) {
        proposalPopup.on('close-complete', resetProposalForm);
    }
}

function destroy(container: Element | Document = document) {
    clearTimeout(timer);
    clearTimeout(formUploadClosingTimer);

    if (formUploadOpenBtn) {
        formUploadOpenBtn.removeEventListener('click', onFormUploadOpenBtnClick);
    }

    if (formUploadCloseBtn) {
        formUploadCloseBtn.removeEventListener('click', onFormUploadCloseBtnClick);
    }

    if (proposalPopup) {
        proposalPopup.off('close-complete', resetProposalForm);
    }

    dispatcher.off('dropzone-success.proposal', onProposalDropzoneSuccess);
    dispatcher.off('dropzone-remove.proposal', onProposalDropzoneRemove);
}

const _module = { init, destroy };

export default _module;
