import { ITransitionData } from '@barba/core';
import anime from 'animejs';

import baseTransition from './_base';
import revealTransition, { immediatelyHideRevealer } from './reveal';

export default {
    name: 'to-main',
    to: { namespace: 'index-page' },

    beforeLeave() {
        revealTransition.beforeLeave();
    },

    async leave({ current }: ITransitionData) {
        await revealTransition.leave();
        current.container.style.opacity = '0';
    },

    async enter(data: ITransitionData) {
        // await fadeTransition.enter(data);
        await baseTransition.enter(data);
        immediatelyHideRevealer();
        anime({
            targets: data.next.container,
            duration: 400,
            opacity: [0, 1],
            easing: 'easeInOutSine',
        });
    },
};
