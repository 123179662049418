import { ITransitionData } from '@barba/core';
import { timeout } from '@chipsadesign/frontend-utils';

export default {
    name: 'detail-to-news',
    from: { namespace: 'news-detail-page' },
    to: { namespace: ['news-page', 'partners-page'] },

    beforeEnter({ next }: ITransitionData) {
        Array.from(next.container.querySelectorAll('[data-reveal]')).forEach((el) => {
            el.removeAttribute('data-reveal');
        });
    },

    async leave({ current, next }: ITransitionData) {
        const contentElement = current.container.querySelector('.js-news-detail');
        if (contentElement) {
            contentElement.classList.remove('lit-popup--opened');
            await timeout(1000);
            next.container.focus();
        }
    },
};
