import delegate from 'delegate';

import { dispatcher } from './dispatcher';

function init() {
    delegate(document, '.js-vacancy-upload-vc', 'click', (event: any) => {
        dispatcher.dispatch('vacancy-upload-open', { vacancyId: event.delegateTarget.dataset.vacancyId });
    });
}

const _module = { init };

export default _module;
