import anime from 'animejs';

export function getFocusableElements(container: Element | Document = document) {
    return Array.from(
        container.querySelectorAll('button, a, input, textarea, select, details, [tabindex]:not([tabindex="-1"]'),
    ).filter((el) => !el.hasAttribute('disabled'));
}

export function createHeroPageInitialAnimation(container: Element) {
    const breadcrumbsElement = container.querySelector('.js-initial-screen__breadcrumbs');
    const titleElement = container.querySelector('.js-initial-screen__title');
    const secondaryElement = container.querySelector('.js-initial-screen__secondary');
    const illustrationElement = container.querySelector('.js-initial-screen-illustration');
    const tl = anime.timeline({ easing: 'easeOutCubic' });

    tl.add({
        targets: breadcrumbsElement,
        opacity: [0, 1],
        translateY: [30, 0],
        easing: 'easeOutCubic',
        duration: 1000,
    })
        .add(
            {
                targets: titleElement,
                translateY: [30, 0],
                opacity: [0, 1],
                duration: 1000,
            },
            '-=700',
        )
        .add(
            {
                targets: secondaryElement,
                translateY: [30, 0],
                opacity: [0, 1],
                duration: 1000,
            },
            '-=700',
        )
        .add(
            {
                targets: illustrationElement,
                translateY: [30, 0],
                opacity: [0, 1],
                duration: 1000,
            },
            '-=700',
        );

    tl.pause();

    return tl;
}
