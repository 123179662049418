import LitPopup from 'lit-popup';
import barba from '@barba/core';

import { dispatcher } from './dispatcher';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

const name = 'menu';
let wasBodyLocked = false;

function lockScroll() {
    wasBodyLocked = document.body.classList.contains('no-scroll');

    if (!wasBodyLocked) {
        lockBodyScroll();
    }
}

function unlockScroll() {
    if (!wasBodyLocked) {
        unlockBodyScroll();
    }
}

function init() {
    const menuElement = document.querySelector(`[data-lit-popup="${name}"]`);

    if (menuElement) {
        const menuOpenBtn = document.querySelector('.js-menu-open-btn');

        const popup = new LitPopup(name, {
            onOpen: () => {
                lockScroll();

                if (menuOpenBtn) {
                    menuOpenBtn.classList.add('menu-btn--hidden');
                }
            },
            onOpenComplete: () => {
                dispatcher.dispatch('popup-opened', { name });
            },
            onClose: () => {
                dispatcher.dispatch('popup-closed', { name });
            },
            onCloseComplete: () => {
                unlockScroll();

                if (menuOpenBtn) {
                    menuOpenBtn.classList.remove('menu-btn--hidden');
                }
            },
        });

        barba.hooks.enter(() => {
            if (popup.isOpen) {
                popup.close();
            }
        });
    }
}

const _module = { init };

export default _module;
