<template>
    <div class="collapse" :class="{ 'collapse--expanded': expanded }">
        <div class="collapse__content" :class="contentClass">
            <slot></slot>
        </div>
        <button
            :aria-expanded="String(expanded)"
            @click="expanded = !expanded"
            :aria-label="expanded ? 'Свернуть' : 'Развернуть'"
            class="icon-link collapse__trigger"
            :class="triggerClass"
        >
            <span class="icon-link__icon plus" aria-hidden="true">
                <span></span>
                <span></span>
            </span>
            <span class="icon-link__text">{{ expanded ? expandedText : collapsedText }}</span>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        triggerClass: { type: String },
        contentClass: { type: String },
        expandedText: { type: String, default: 'Меньше' },
        collapsedText: { type: String, default: 'Больше' },
    },

    data() {
        return {
            expanded: false,
        };
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';

x-collapse {
    display: block;
    width: 100%;
}

.plus {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    position: relative;
    top: 0.2px;

    @media screen and (max-width: 1024px) {
        --size: 20px;
    }

    > span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 20px;
        top: 50%;
        background-color: currentColor;

        &:nth-child(1) {
            transform: translate(0, -50%);
        }

        &:nth-child(2) {
            transition: transform 0.2s ease;
            transform: translate(0, -50%) rotate(-90deg);
        }
    }

    .collapse--expanded & {
        > span {
            &:nth-child(2) {
                transform: translate(0, -50%);
            }
        }
    }
}

.collapse__trigger {
    display: flex;
    align-items: center;
    width: 100%;

    &::before {
        content: '';
        height: 1px;
        flex: 1 1 auto;
        background-color: $secondary;
        margin-right: 108px;

        @media screen and (max-width: 1024px) {
            margin-right: 45px;
        }

        @media screen and (max-width: 576px) {
            margin-right: 20px;
        }
    }
}

.collapse__content {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s easeInOutQuad;
    will-change: max-height;

    .collapse--expanded > & {
        max-height: 50em;
        transition: max-height 0.7s easeOutQuad;
    }
}
</style>
