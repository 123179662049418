import { ITransitionData } from '@barba/core';
import { timeout } from '@chipsadesign/frontend-utils';
import anime from 'animejs';

import baseTransition from './_base';
import { createHeroPageInitialAnimation } from '../utils';
import { dispatcher } from '../inits/dispatcher';

const ANIMATION_DURATION = 1000;
const revealerInner = document.querySelector('.js-page-revealer-inner') as HTMLElement;
const angle = 35;

revealerInner.style.width = `calc(100vw * ${Math.abs(Math.cos((angle * Math.PI) / 180))} + 100vh * ${Math.abs(
    Math.sin((angle * Math.PI) / 180),
)})`;
revealerInner.style.height = `calc(100vw * ${Math.abs(Math.sin((angle * Math.PI) / 180))} + 100vh * ${Math.abs(
    Math.cos((angle * Math.PI) / 180),
)})`;
revealerInner.style.transform = `rotate3d(0,0,1,${angle}deg)`;

export function immediatelyHideRevealer() {
    anime({
        targets: revealerInner,
        translateY: '100%',
        duration: 0,
    });
}

immediatelyHideRevealer();

export default {
    name: 'reveal',

    once({ next }: ITransitionData) {
        const tl = createHeroPageInitialAnimation(next.container);
        dispatcher.once('preloader-complete', () => {
            tl.play();
        });
    },

    beforeLeave() {
        baseTransition.beforeLeave(ANIMATION_DURATION);
    },

    async leave() {
        baseTransition.leave();

        anime({
            targets: revealerInner,
            translateY: ['100%', 0],
            duration: ANIMATION_DURATION,
            easing: 'easeInOutCubic',
        });

        await timeout(ANIMATION_DURATION + 100);
    },

    async enter(data: ITransitionData) {
        await baseTransition.enter(data);

        anime({
            targets: revealerInner,
            translateY: [0, '-100%'],
            duration: ANIMATION_DURATION,
            easing: 'easeInOutCubic',
        });

        anime({
            targets: '.js-transition-reveal',
            delay: (el) => (el.dataset.revealDelay ? parseFloat(el.dataset.revealDelay) : 0),
            translateX: [-77, 0],
            translateY: [100, 0],
            duration: ANIMATION_DURATION * 1.75,
            easing: 'easeOutExpo',
        });
    },
};
