import { timeout } from '@chipsadesign/frontend-utils';

import popups from './popups';

const name = 'initial-screen-video-popup';

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll(`[data-lit-popup="${name}"]`));

    elements.forEach((el) => {
        const popup = popups.getInstanceByElement(el);
        const videoElement = el.querySelector('video.js-initial-screen-video') as HTMLVideoElement | null;

        if (popup && videoElement) {
            popup.setCloseAnimation(async () => {
                await timeout(600);
            });

            popup.on('open', () => {
                if (videoElement.readyState === 4) {
                    videoElement.play();
                } else {
                    videoElement.addEventListener('canplaythrough', () => videoElement.play(), { once: true });
                }
            });

            popup.on('close-complete', () => {
                videoElement.pause();
                videoElement.currentTime = 0;
            });
        }
    });
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll(`[data-lit-popup="${name}"]`));

    elements.forEach((el) => {
        // const popup = popups.getInstanceByElement(el);
        // if (popup) {
        //     popup.on('open', () => {
        //         console.log('opened');
        //     });
        // }
    });
}

const _module = { init, destroy };

export default _module;
