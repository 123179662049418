import anime from 'animejs';
import type { AnimeInstance, AnimeParams } from 'animejs';

import { destroyAnimeInstance } from '../utils/destroy-anime-instance';

export type MorphingAnimation = {
    readonly destroy: () => void;
};

export const supportsCssPathMorphing = window.CSS?.supports?.('d', 'path("M0 0")') || false;
const map = new WeakMap<Element, AnimeInstance>();

function checkEntry(entry: IntersectionObserverEntry) {
    const { target, isIntersecting } = entry;
    const animation = map.get(target);

    if (isIntersecting) {
        target.classList.add('animating');

        if (animation) {
            animation.play();
        }
    } else {
        target.classList.remove('animating');

        if (animation) {
            animation.pause();
        }
    }
}

const observer = new IntersectionObserver((entries) => {
    entries.forEach(checkEntry);
});

export const createMorphingAnimation = (targetElement: Element | null, options?: AnimeParams): MorphingAnimation => {
    if (!targetElement) {
        throw new Error('Element not found.');
    }

    let animation: AnimeInstance | null;
    const path = targetElement.querySelector('.morphing');

    if (!supportsCssPathMorphing) {
        animation = anime({ ...options, targets: path });
        animation.pause();
        map.set(targetElement, animation);
    }

    observer.observe(targetElement);

    const destroy = () => {
        observer.unobserve(targetElement);

        if (animation) {
            map.delete(targetElement);
            destroyAnimeInstance(animation);
            animation = null;
        }
    };

    return { destroy } as const;
};
