import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import * as Cookies from 'js-cookie';

import { fetchGlide } from './dynamic-modules';
import Collapse from './components/vue/Collapse/Collapse.vue';
import Loader from './components/vue/Loader/Loader.vue';
import ScrolltopButton from './components/vue/ScrolltopButton/ScrolltopButton.vue';

Vue.use(vueCustomElement);

export function registerCustomElements() {
    Vue.customElement('x-collapse', Collapse);

    Vue.customElement('x-loader', Loader);

    Vue.customElement('scrolltop-button', ScrolltopButton);

    if (Cookies.get('COOKIES_USAGE_ACCEPTED') !== 'true') {
        Vue.customElement('cookies-agreement', () =>
            import(
                /* webpackChunkName: "CookiesAgreement" */ './components/vue/CookiesAgreement/CookiesAgreement.vue'
            ).then((m) => m.default),
        );
    }

    Vue.customElement('timeline-slider', () =>
        Promise.all([
            import(/* webpackChunkName: "TimelineSlider" */ './components/vue/TimelineSlider/TimelineSlider.vue').then(
                (m) => m.default,
            ),
            fetchGlide(),
        ]).then(([slider]) => slider),
    );

    Vue.customElement('wide-slider', () =>
        Promise.all([
            import(/* webpackChunkName: "WideSlider" */ './components/vue/WideSlider/WideSlider.vue').then(
                (m) => m.default,
            ),
            fetchGlide(),
        ]).then(([slider]) => slider),
    );

    Vue.customElement('x-dropzone', () =>
        import(/* webpackChunkName: "Dropzone" */ './components/vue/Dropzone/Dropzone.vue').then((m) => m.default),
    );

    Vue.customElement('vacancy-dropzone', () =>
        import(/* webpackChunkName: "VacancyDropzone" */ './components/vue/VacancyDropzone/VacancyDropzone.vue').then(
            (m) => m.default,
        ),
    );

    Vue.customElement('yandex-map', () =>
        import(/* webpackChunkName: "YandexMap" */ './components/vue/YandexMap/YandexMap.vue').then((m) => m.default),
    );
}
