<template>
    <button
        ref="scrolltop"
        title="Наверх"
        aria-label="Наверх"
        @click="scrollTop"
        class="icon icon-secondary scrolltop-btn"
        :class="{ 'scrolltop-btn--visible': visible }"
    >
        <icon-arrow></icon-arrow>
    </button>
</template>

<script>
import ArrowIcon from '../svg/Arrow.vue';

const supportsNativeSmoothScrolling = window.CSS?.supports?.('scroll-behavior', 'smooth');

export default {
    components: { 'icon-arrow': ArrowIcon },

    data() {
        return {
            visible: false,
            observer: new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        this.visible = entry.isIntersecting;
                    });
                },
                { rootMargin: '250% 0% -250% 0%' },
            ),
        };
    },

    mounted() {
        this.observer.observe(document.body);
    },

    beforeDestroy() {
        this.observer.disconnect();
    },

    methods: {
        scrollTop() {
            if (supportsNativeSmoothScrolling) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                window.scrollTo({ top: 0 });
                // const [gsap, ScrollToPlugin] = await Promise.all([fetchGsap(), fetchScrollToPlugin()]);
                // gsap.registerPlugin(ScrollToPlugin);
                // gsap.to(window, { duration: 0.5, scrollTo: 0 });
            }
        },
    },
};
</script>

<style lang="scss">
@import '../../../../css/base/variables';

.scrolltop-btn {
    position: fixed;
    z-index: 1;
    bottom: var(--offset-y);
    right: var(--offset-x);
    transform: rotate(-90deg);
    transition: opacity 0.2s ease, visibility 0.2s ease,
        background-color 0.2s ease;

    @media screen and (max-width: 767px) {
        display: none;
    }

    &:not(.scrolltop-btn--visible) {
        opacity: 0;
        visibility: hidden;
    }

    [stroke] {
        stroke: #fff;
    }

    body.no-scroll & {
        right: calc(var(--offset-x) + var(--scrollbar-width, 0px));
    }

    @mixin hover() {
        background-color: $primary;
    }

    &:not(:disabled):focus.focus-visible {
        @include hover();
    }

    @media (pointer: fine) {
        &:not(:disabled):hover {
            @include hover();
        }
    }
}
</style>
