import barba, { IViewData } from '@barba/core';
import youtubePopup from '../inits/youtube-popup';
import loadScript from '../../modules/load-script';

import KEYCODES from '../utils/keyCodes';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

function onKeydown(event: KeyboardEvent) {
    if (event.keyCode === KEYCODES.ESC) {
        const closeBtn = document.querySelector('a.js-modal-close-btn') as HTMLAnchorElement | null;
        if (closeBtn?.href) {
            barba.go(closeBtn.href);
        }
    }
}

let youtubeLoaded = false;

export default {
    namespace: 'news-detail-page',

    beforeEnter({ next }: IViewData) {
        lockBodyScroll();
        document.addEventListener('keydown', onKeydown);
        const onYoutubeReady = () => {
            youtubePopup.init(next.container);
        };

        if (!youtubeLoaded) {
            loadScript('https://www.youtube.com/iframe_api').then(() => {
                youtubeLoaded = true;
            });
        }

        if (youtubeLoaded) {
            onYoutubeReady();
        } else {
            (window as any).onYouTubeIframeAPIReady = onYoutubeReady;
        }
    },

    beforeLeave({ current }: IViewData) {
        document.removeEventListener('keydown', onKeydown);
        youtubePopup.destroy(current.container);
    },

    afterLeave() {
        unlockBodyScroll();
    },
};
