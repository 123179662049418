import { createMorphingAnimation } from './base-morphing';

export const createTreesOrangeOneAnimation = (container: Element | Document = document) => {
    const svg = container.querySelector('.trees-orange-1');
    return createMorphingAnimation(svg, {
        d: [
            {
                value: [
                    'M827,173.88s-101.47,-157.73,-259.17,-173.31c-89.68,-8.86,-62.9,87.71,-140.3,93.58c-21.86,1.66,-26.85,-65.87,-45.46,-65.77c-15,0.09,-16.94,15.41,-30.09,18.49s-31,-13,-44.74,-14.63s-23,18.7,-26.71,20.91c-9.93,5.91,-28.56,-20.87,-42,-19.81c-15.24,1.21,-50.65,41.6,-67.53,46.56c-14.76,4.35,-40.41,-16.3,-59.76,-15.26c-26.9,1.44,-59.72,64,-75.73,81.51c-12.51,13.73,-35.51,27.73,-35.51,27.73z',
                    'M827,194.85s-81.47,-178.73,-239.17,-194.31c-89.68,-8.86,-62.9,93.71,-140.3,99.58c-21.86,1.66,-26.85,-71.87,-45.46,-71.77c-15,0.09,-16.94,21.41,-30.09,24.49s-31,-13,-44.74,-14.63s-23,18.7,-26.71,20.91c-9.93,5.88,-28.53,-28.87,-41.98,-27.81c-15.26,1.2,-50.67,49.6,-67.53,54.56c-14.76,4.35,-40.41,-23.3,-59.76,-22.26c-26.9,1.44,-59.72,64,-75.73,81.51c-12.53,13.73,-55.53,49.73,-55.53,49.73z',
                ],
            },
            {
                value:
                    'M827,173.88s-101.47,-157.73,-259.17,-173.31c-89.68,-8.86,-62.9,87.71,-140.3,93.58c-21.86,1.66,-26.85,-65.87,-45.46,-65.77c-15,0.09,-16.94,15.41,-30.09,18.49s-31,-13,-44.74,-14.63s-23,18.7,-26.71,20.91c-9.93,5.91,-28.56,-20.87,-42,-19.81c-15.24,1.21,-50.65,41.6,-67.53,46.56c-14.76,4.35,-40.41,-16.3,-59.76,-15.26c-26.9,1.44,-59.72,64,-75.73,81.51c-12.51,13.73,-35.51,27.73,-35.51,27.73z',
            },
        ],
        easing: 'easeInOutCubic',
        duration: 3500 * 0.8,
        loop: true,
    });
};
