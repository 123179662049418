/* global PUBLIC_PATH */
import { Howl } from 'howler';

let isPlaying = localStorage.getItem('isBgSoundDisabled') !== 'true';
const togglers = Array.from(document.querySelectorAll('.js-sound-toggler'));

const bgSound = new Howl({
    src: [`${PUBLIC_PATH}audio/background.mp3`],
    loop: true,
    onplayerror: () => {
        bgSound.once('unlock', () => {
            if (isPlaying) {
                bgSound.play();
                bgSound.fade(0, 1, 500);
            }
        });
    },
});

function play() {
    if (!bgSound.playing()) {
        bgSound.play();
    }

    bgSound.fade(0, 1, 500);
}

function pause() {
    bgSound.fade(1, 0, 300);
}

function lowerOnTransition() {
    bgSound.fade(1, 0.3, 300);
}

function restoreAfterTransition() {
    bgSound.fade(0.3, 1, 300);
}

function playing() {
    return isPlaying;
}

function setPlayingState(shouldPlay: boolean) {
    togglers.forEach((toggler) => {
        const textElement = toggler.querySelector('.js-sound-toggler-text');

        if (textElement) {
            textElement.textContent = shouldPlay ? 'Выключить' : 'Включить';
        }

        if (shouldPlay) {
            toggler.classList.add('sound-toggle-btn--active');
        } else {
            toggler.classList.remove('sound-toggle-btn--active');
        }

        localStorage.setItem('isBgSoundDisabled', `${!shouldPlay}`);
    });

    if (shouldPlay) {
        play();
    } else {
        pause();
    }
}

function toggleSound() {
    isPlaying = !isPlaying;
    setPlayingState(isPlaying);
}

function init() {
    togglers.forEach((toggler) => {
        toggler.addEventListener('click', toggleSound);
    });

    bgSound.once('load', () => {
        setPlayingState(isPlaying);
    });

    document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
            if (isPlaying) {
                play();
            }
        } else if (isPlaying) {
            pause();
        }
    });
}

const _module = { init, toggleSound, setPlayingState, play, pause, playing, lowerOnTransition, restoreAfterTransition };

export default _module;
