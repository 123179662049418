/* global lightGallery */
import { fetchLightGallery } from '../dynamic-modules';
import { lockBodyScroll, unlockBodyScroll } from '../utils/lock-body-scroll';

const selector = '.js-lightgallery';

async function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll(selector));

    if (elements.length > 0) {
        await fetchLightGallery();

        elements.forEach((el) => {
            lightGallery(el, {
                selector: '.js-lightgallery__link',
            });

            el.addEventListener('onBeforeOpen', lockBodyScroll);
            el.addEventListener('onCloseAfter', unlockBodyScroll);
        });
    }
}

const module = { init };

export default module;
