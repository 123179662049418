import { ITransitionData } from '@barba/core';

import { dispatcher } from '../inits/dispatcher';
import type { Asset } from '../utils/types';
import { loadAssets } from '../utils/load-assets';
import sound from '../inits/sound';

const pageLoader = document.querySelector('.js-page-loader');
let timer: NodeJS.Timeout;

async function loadPageAssets(element: Element) {
    const assets = Array.from(element.querySelectorAll('img, video')) as Asset[];
    await loadAssets(assets);
}

export default {
    beforeLeave(animationDuration: number) {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            if (pageLoader) {
                pageLoader.classList.add('visible');
            }
        }, animationDuration + 700);
    },

    leave() {
        if (sound.playing()) {
            sound.lowerOnTransition();
        }
    },

    async enter({ next }: ITransitionData) {
        window.scrollTo({ top: 0, behavior: 'auto' });
        await loadPageAssets(next.container);

        if (timer) {
            clearTimeout(timer);
        }

        if (pageLoader) {
            pageLoader.classList.remove('visible');
        }

        requestAnimationFrame(() => {
            dispatcher.dispatch('preloader-complete');

            if (sound.playing()) {
                sound.restoreAfterTransition();
            }
        });
    },
};
