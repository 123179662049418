import { fetchImask } from '../dynamic-modules';

const map = new WeakMap<Element, any>();
export const thousandsSeparator = ' ';

function getInstanceByElement(element: Element) {
    return map.get(element);
}

async function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('input[data-mask="tel"]')) as HTMLInputElement[];

    if (elements.length > 0) {
        const { default: Imask } = await fetchImask();

        elements.forEach((el) => {
            const imask = IMask(el, { mask: '+{7} (000) 000-00-00' });
            map.set(el, imask);
        });
    }
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll('input[data-mask="tel"]')) as HTMLElement[];
    elements.forEach((el) => {
        const imask = getInstanceByElement(el);
        if (imask) {
            imask.destroy();
            map.delete(el);
        }
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
