type PopupEvent = Event & {
    detail?: {
        triggerElement?: HTMLElement;
    };
};

const playersMap = new WeakMap<HTMLElement, any>();

const onOpen = (event: PopupEvent) => {
    if (event.detail) {
        const { triggerElement } = event.detail;
        const popupEl = event.target as HTMLElement;
        const videoEl = popupEl.querySelector('.js-video-frame');

        if (triggerElement) {
            const youtubeId = triggerElement.dataset.videoId;

            const player = new (window as any).YT.Player(videoEl, {
                playerVars: {
                    enablejsapi: 1,
                    origin: window.location.origin,
                    playlist: youtubeId,
                },
                height: '100%',
                width: '100%',
                videoId: youtubeId,
                events: {
                    onReady: () => {
                        player.playVideo();
                    },
                },
            });

            playersMap.set(popupEl, player);
        }
    }
};

const onClose = (event: PopupEvent) => {
    const popupEl = event.target as HTMLElement;
    if (popupEl) {
        const videoWrapper = popupEl.querySelector('.js-video-frame-wrapper');
        if (videoWrapper) {
            videoWrapper.innerHTML = '';
            const div = document.createElement('div');
            div.classList.add('js-video-frame');
            videoWrapper.appendChild(div);
        }

        const player = playersMap.get(popupEl);

        if (player) {
            player.stopVideo();
        }
    }
};

function init(container: HTMLElement | Document = document) {
    const youtubePopups = Array.from(container.querySelectorAll<HTMLElement>('[data-lit-popup="youtube"]'));
    youtubePopups.forEach((popup) => {
        popup.addEventListener('open', onOpen);
        popup.addEventListener('close', onClose);
    });
}

function destroy(container: HTMLElement | Document = document) {
    const youtubePopups = Array.from(container.querySelectorAll<HTMLElement>('[data-lit-popup="youtube"]'));
    youtubePopups.forEach((popup) => {
        popup.removeEventListener('open', onOpen);
        popup.removeEventListener('close', onClose);
        const player = playersMap.get(popup);

        if (player) {
            player.destroy();
            playersMap.delete(popup);
        }
    });
}

const _module = { init, destroy };

export default _module;
