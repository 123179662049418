import { timeout } from '@chipsadesign/frontend-utils';

import { dispatcher } from '../inits/dispatcher';
import { loadAssets } from '../utils/load-assets';
import type { Asset } from '../utils/types';

export const MIN_PRELOADER_DURATION = 1400;

export function createPreloader() {
    const container = document.querySelector('.js-preloader');
    const assets = Array.from(document.querySelectorAll('img, video')) as Asset[];

    function onComplete() {
        dispatcher.dispatch('preloader-complete');

        if (container?.parentElement) {
            container.parentElement.removeChild(container);
        }
    }

    async function init() {
        if (container) {
            loadAssets(assets);
            try {
                await Promise.all([/* loadAssets(assets), */ timeout(MIN_PRELOADER_DURATION)]);
            } finally {
                container.classList.add('preloader--leaving');
                await timeout(500);
            }
        }

        onComplete();
    }

    return { init } as const;
}
