import { createMorphingAnimation } from './base-morphing';

export const createTreesGreenOneAnimation = (container: Element | Document = document) => {
    const svg = container.querySelector('.trees-green-1');
    return createMorphingAnimation(svg, {
        d: [
            {
                value: [
                    'M774.46,152.63c-19,-8.37,-51.35,3.25,-83,-3.63c-49,-10.66,-82,-48,-129,-49c-28.06,-0.6,-36.75,8,-69,5c-23.79,-2.23,-49,-48.75,-85,-55c-23,-4,-46,7.63,-61,0.63c-8.6,-4,-42,-48,-58.84,-50.28c-17.13,-2.35,-29.86,7.35,-43.36,17.13c-33.8,24.52,-42.68,-3.06,-102.58,-8.18c-17.06,-1.45,-33.83,1.8,-48.22,11.7c-16,11,-12.9,17.7,-44,28c-62,20.54,-70.86,184.08,-4,226.63c44,28,69,31,676,19c76.01,-1.5,97.38,-122.02,52,-142z',
                    'M785,151.63c-19,-8.37,-61.35,9.25,-93,2.37c-49,-10.66,-72,-54,-119,-55c-28.07,-0.6,-46.76,14,-79,11c-23.79,-2.23,-39,-54.75,-75,-61c-23,-4,-56,13.63,-71,6.63c-8.6,-4,-32,-53,-48.84,-55.28c-17.1,-2.35,-29.84,7.35,-43.33,17.13c-33.83,24.52,-42.68,-4.06,-102.58,-9.18c-17.06,-1.45,-33.83,1.8,-48.25,11.7c-16,11,-12.87,17.7,-44,28c-62,20.54,-88.83,190.08,-22,232.63c44,28,69,31,676,19c76,-1.5,115.41,-128.02,70,-148z',
                ],
            },
            {
                value:
                    'M774.46,152.63c-19,-8.37,-51.35,3.25,-83,-3.63c-49,-10.66,-82,-48,-129,-49c-28.06,-0.6,-36.75,8,-69,5c-23.79,-2.23,-49,-48.75,-85,-55c-23,-4,-46,7.63,-61,0.63c-8.6,-4,-42,-48,-58.84,-50.28c-17.13,-2.35,-29.86,7.35,-43.36,17.13c-33.8,24.52,-42.68,-3.06,-102.58,-8.18c-17.06,-1.45,-33.83,1.8,-48.22,11.7c-16,11,-12.9,17.7,-44,28c-62,20.54,-70.86,184.08,-4,226.63c44,28,69,31,676,19c76.01,-1.5,97.38,-122.02,52,-142z',
            },
        ],
        easing: 'easeInOutCubic',
        duration: 3500 * 0.8,
        loop: true,
    });
};
