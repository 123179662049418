import anime from 'animejs';

import { dispatcher } from '../inits/dispatcher';

export function createMainPageRevealAnimation() {
    const tl = anime.timeline({
        easing: 'easeOutCubic',
        autoplay: false,
        complete: () => {
            dispatcher.dispatch('main-page-reveal-complete');
        },
    });

    tl /* .add({
        targets: '.js-header',
        duration: 1500,
        opacity: [0, 1],
    }) */.add(
        {
            targets: '.js-main-title',
            duration: 1500,
            opacity: [0, 1],
            translateY: [30, 0],
        },
        0,
    )
        .add(
            {
                targets: '.js-scroll-hint',
                duration: 1000,
                scale: [0, 1],
                easing: 'easeInOutBack',
            },
            100,
        )
        .add(
            {
                targets: '.js-main-subtitle',
                duration: 1500,
                opacity: [0, 1],
                translateY: [30, 0],
            },
            300,
        )
        .add(
            {
                targets: '.js-main-store-link',
                duration: 1500,
                opacity: [0, 1],
                translateY: [30, 0],
            },
            500,
        )
        .add(
            {
                targets: '.js-main-play-video',
                duration: 1500,
                opacity: [0, 1],
                translateY: [30, 0],
            },
            700,
        )
        .add(
            {
                targets: '.fourth-plan-green-hill-1',
                duration: 1500,
                translateY: ['200%', 0],
            },
            0,
        )
        .add(
            {
                targets: '.js-third-plan-orange-hill-1',
                duration: 1500,
                translateY: ['200%', 0],
            },
            0,
        )
        .add(
            {
                targets: '.js-trees-green-1',
                duration: 1500,
                translateY: ['100%', 0],
            },
            0,
        )
        .add(
            {
                targets: '.js-trees-green-2',
                duration: 1500,
                translateY: ['300%', 0],
            },
            0,
        )
        .add(
            {
                targets: '.js-third-plan-green-hill-1',
                duration: 1500,
                translateY: ['100%', 0],
            },
            0,
        )
        .add(
            {
                targets: '.js-third-plan-green-hill-2',
                duration: 1500,
                translateY: ['100%', 0],
            },
            0,
        )
        .add(
            {
                targets: '.js-second-plan-hill-1',
                duration: 1500,
                translateY: ['100%', 0],
            },
            0,
        )
        .add({ targets: '.js-screen1-leaf-container', duration: 1000, translateY: ['100%', 0] }, 500);

    const revealItemsObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                revealItemsObserver.unobserve(entry.target);
                entry.target.classList.add('revealed');
            }
        });
    });

    function play() {
        tl.play();
    }

    function destroy() {
        revealItemsObserver.disconnect();
    }

    Array.from(document.querySelectorAll('.js-reveal')).forEach((el) => {
        revealItemsObserver.observe(el);
    });

    return { play, destroy } as const;
}
